var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-4 flex flex-col items-start w-full border-r-2" },
    [
      _vm._t("title", function () {
        return [
          _c("span", { staticClass: "capitalize" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]
      }),
      _vm._t("value", function () {
        return [
          _c("span", { staticClass: "text-3xl text-orange-500" }, [
            _vm._v(_vm._s(_vm.formattedValue)),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }