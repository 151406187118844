<template>
  <div class="p-4 flex flex-col items-start w-full border-r-2">
    <slot name="title">
      <span class="capitalize">{{ title }}</span>
    </slot>
    <slot name="value">
      <span class="text-3xl text-orange-500">{{ formattedValue }}</span>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'BoxColumn',
  props: {
    title: {
      type: String,
      default: () => null,
    },
    value: {
      type: [String, Number],
      default: () => null,
    },
  },
  computed: {
    formattedValue() {
      if (typeof this.value === 'number') {
        return this.$options.filters.numFormat(this.value);
      }
      return this.value;
    },
  },
};
</script>
