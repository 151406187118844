<template>
  <div
    class="totals flex flex-row items-center w-full bg-gray-100 rounded border border-gray-300 shadow text-xs font-bold text-gray-500 overflow-auto"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Box',
};
</script>
