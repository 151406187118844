import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, TOTALS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import Metric from '@/entities/socialAudience/Metric';

export const getResources = clientId => ({
  [ANALYTICS_RESOURCE]: null,
  [CLIENT_RESOURCE]: clientId,
  [TOTALS_RESOURCE]: null,
});

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<Metric>}
 */
export async function getAnalyticsTotals(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(clientId));
  const {
    data: { metrics },
  } = await api.get(partialUrl, params.buildWithoutPage());
  return new Metric(metrics.globals, metrics.cost);
}
