import { socialAudience } from '..';
import { getAnalytics } from './getAnalytics';
import { getAnalyticsTotals } from './getAnalyticsTotal';
import { getAnalyticsByCampaign } from './getAnalyticsByCampaign';
import createAnalyticsReport from './createAnalyticsReport';
import getAnalyticsReport from './getAnalyticsReport';
import getAnalyticsReportStatus from './getAnalyticsReportStatus';

export const api = socialAudience;

export {
  getAnalytics,
  createAnalyticsReport,
  getAnalyticsTotals,
  getAnalyticsReport,
  getAnalyticsReportStatus,
  getAnalyticsByCampaign,
};
