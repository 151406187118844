var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker", {
                  key: _vm.datepickerKey,
                  staticClass: "w-auto text-sm font-light text-gray-800",
                  attrs: {
                    id: "filter-date",
                    name: "filterDates",
                    value: _vm.quickFiltersDate,
                    separator: "to",
                    "cancel-error": "",
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                    required: "",
                    "single-date-picker": false,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("sun-filter-layout", {
              staticClass: "mb-5",
              attrs: {
                id: "filter-box",
                "filters-available": _vm.availableFilters,
                "filters-added": _vm.filterFind,
                "has-error": _vm.anyError,
                "show-reset": "",
              },
              on: {
                change: _vm.changeFilters,
                close: _vm.filtersResetErrors,
                remove: _vm.filtersResetErrors,
                reset: _vm.resetFilters,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "quickFilters",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap lg:flex-no-wrap min-w-max",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "m-1" },
                              [
                                _c("sun-search-input", {
                                  attrs: {
                                    "class-input": "text-sm",
                                    value: _vm.filterQuick["campaign.name"],
                                  },
                                  on: {
                                    search: function ($event) {
                                      return _vm.onQuickFiltersSearch(
                                        $event,
                                        "campaign.name"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: `filter.campaign.name`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.campaign.status`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("sun-select", {
                          staticClass: "w-24",
                          attrs: {
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "close-on-select": "",
                            "disable-selected-options": "",
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            label: filter.textBy,
                            options: filter.options,
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            "track-by": filter.trackBy,
                            value: _vm.filtersMakeFiltersForSelect(value),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.advertiser.id`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("advertiser-filter", {
                          attrs: {
                            api: _vm.currentApi,
                            "client-id": _vm.activeClient.id,
                            "disable-selected-options": "",
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            service: _vm.getAdvertisers,
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            value: _vm.filtersMakeFiltersForSelect(value),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.campaign.type`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("sun-select", {
                          staticClass: "w-24",
                          attrs: {
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "close-on-select": "",
                            "disable-selected-options": "",
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            label: filter.textBy,
                            options: filter.options,
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            "track-by": filter.trackBy,
                            value: _vm.filtersMakeFiltersForSelect(value),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center mb-2 lg:pr-1 lg:mb-0",
                  },
                  [
                    _c("columns-selector-social-audience", {
                      attrs: { "default-columns": _vm.availableColumns },
                      on: {
                        change: function ($event) {
                          _vm.headers = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "h-10 mb-2 breakdown lg:mb-0 flex items-center",
                  },
                  [
                    _c("breakdown-wrapper", {
                      attrs: {
                        label: "Select Breakdowns",
                        items: _vm.breakdownOptions,
                        "hide-secondary": "",
                        disabled: _vm.breakdownDisabled,
                      },
                      on: { reset: _vm.resetBreakdown },
                      model: {
                        value: _vm.breakdown,
                        callback: function ($$v) {
                          _vm.breakdown = $$v
                        },
                        expression: "breakdown",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-row items-center mb-2 ml-1 lg:mb-0",
                  },
                  [
                    _c(
                      "asterix-dropdown",
                      {
                        attrs: {
                          "button-classes":
                            "flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center",
                          disabled: _vm.isLoading || _vm.reportToastLoading,
                          options: _vm.reportOptions,
                        },
                        on: { change: _vm.handlerReport },
                        scopedSlots: _vm._u([
                          {
                            key: "dropdown-header",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap",
                                  },
                                  [_vm._v("Download as")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "icon",
                            fn: function (state) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "transition-100",
                                    class: !state.visible ? "rotate-180" : "",
                                  },
                                  [
                                    _c("up-svg", {
                                      staticClass: "w-2 h-2",
                                      class: state.disabled
                                        ? "text-gray-400"
                                        : "text-gray-500",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "block w-4 mr-2" },
                          [_c("download-svg")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col items-center justify-center w-full pb-8",
              },
              [
                _c("sun-data-table", {
                  staticClass: "w-full",
                  attrs: {
                    headers: _vm.availableHeaders,
                    content: _vm.items,
                    detailed: !!_vm.breakdown.primary,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: {
                    expandDetail: _vm.handlerDetail,
                    sort: _vm.onSortTable,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.campaign.name`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: {
                                  sticky: "",
                                  "detail-cell": !_vm.breakdown.primary,
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.start`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _c(
                                "span",
                                { staticClass: "inline-flex" },
                                [
                                  item.startDate
                                    ? _c("date-tooltip", {
                                        staticClass: "date",
                                        attrs: { date: item.startDate },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.notAvailable)),
                                      ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.end`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _c(
                                "span",
                                { staticClass: "inline-flex" },
                                [
                                  item.endDate
                                    ? _c("date-tooltip", {
                                        staticClass: "date",
                                        attrs: { date: item.endDate },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.notAvailable)),
                                      ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.status`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                _c("status-pill", {
                                  attrs: {
                                    status: item.status.value,
                                    color: item.status.color,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.type`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.type.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.advertiser.name`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.advertiser.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.impressions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global.impressions
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.clicks`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global.clicks
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.cost`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      item.analytic.cost.value,
                                      item.analytic.cost.currency
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.netCost`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      item.analytic.netCost.value,
                                      item.analytic.netCost.currency
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.client.finance.fee`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("percentage")(
                                      item.client.finance.fee
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.client.finance.techCost`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("percentage")(
                                      item.client.finance.techCost
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.viewRate`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global.viewRate
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.adStarted`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global.adStarted
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.ad25PercentCompleted`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global
                                        .ad25PercentCompleted
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.ad50PercentCompleted`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global
                                        .ad50PercentCompleted
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.ad75PercentCompleted`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global
                                        .ad75PercentCompleted
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.adCompleted`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global.adCompleted
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.campaign.clickThroughRate`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numFormat")(
                                      item.analytic.metrics.global
                                        .clickThroughRate
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "detailed",
                        fn: function ({ item }) {
                          return [
                            _vm._l(item.children, function (detail, $iRow) {
                              return _c(
                                "sun-data-table-row",
                                { key: $iRow + "_" + item.id },
                                [
                                  _c("sun-data-table-cell", {
                                    attrs: { "detail-cell": "", sticky: "" },
                                  }),
                                  _vm._l(
                                    _vm.availableHeaders,
                                    function (column, $index) {
                                      return [
                                        column && column.visible
                                          ? [
                                              _c(
                                                "sun-data-table-cell",
                                                {
                                                  key: `${$index}_${column}`,
                                                  class: [
                                                    `text-${column.align}`,
                                                  ],
                                                },
                                                [
                                                  column.value ===
                                                  _vm.BREAKDOWN_COLUMNS.NAME
                                                    .value
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "inline-flex w-full",
                                                          },
                                                          [
                                                            _vm.breakdown
                                                              .primary ===
                                                            "Date"
                                                              ? _c(
                                                                  "date-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "date",
                                                                    attrs: {
                                                                      date: detail
                                                                        .analytic
                                                                        .breakdown,
                                                                    },
                                                                  }
                                                                )
                                                              : [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        detail
                                                                          .analytic
                                                                          .breakdown
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.DATE_START
                                                        .value
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "inline-flex",
                                                          },
                                                          [
                                                            detail.startDate
                                                              ? _c(
                                                                  "date-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "date",
                                                                    attrs: {
                                                                      date: detail.startDate,
                                                                    },
                                                                  }
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.notAvailable
                                                                    )
                                                                  ),
                                                                ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.DATE_END.value
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "inline-flex",
                                                          },
                                                          [
                                                            detail.endDate &&
                                                            column.value
                                                              ? _c(
                                                                  "date-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "date",
                                                                    attrs: {
                                                                      date: detail.endDate,
                                                                    },
                                                                  }
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.notAvailable
                                                                    )
                                                                  ),
                                                                ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.IMPRESSIONS
                                                        .value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .impressions
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.CLICKS.value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global.clicks
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.COST.value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                detail.analytic
                                                                  .cost.value,
                                                                item.analytic
                                                                  .cost.currency
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.NET_COST.value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                detail.analytic
                                                                  .netCost
                                                                  .value,
                                                                item.analytic
                                                                  .netCost
                                                                  .currency
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.VTR.value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .viewRate
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.AD_STARTED
                                                        .value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .adStarted
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS
                                                        .AD_25_PERCENT_COMPLETED
                                                        .value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .ad25PercentCompleted
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS
                                                        .AD_50_PERCENT_COMPLETED
                                                        .value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .ad50PercentCompleted
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS
                                                        .AD_75_PERCENT_COMPLETED
                                                        .value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .ad75PercentCompleted
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.AD_COMPLETED
                                                        .value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .adCompleted
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : column.value ===
                                                      _vm.COLUMNS.CTR.value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "numFormat"
                                                              )(
                                                                detail.analytic
                                                                  .metrics
                                                                  .global
                                                                  .clickThroughRate
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : [_vm._v(" - ")],
                                                ],
                                                2
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            }),
                            _vm.canGetMoreItems(item)
                              ? _c("asterix-table-row-load-more", {
                                  attrs: {
                                    loading: _vm.detailPagination.isLoading(
                                      item.id
                                    ),
                                    cols: _vm.availableHeaders.length,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNextDetail(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                            !item.children.length
                              ? _c(
                                  "sun-data-table-row",
                                  [
                                    _c("sun-data-table-cell", {
                                      staticClass: "bg-gray-300",
                                      attrs: { "detail-cell": "" },
                                    }),
                                    _c(
                                      "sun-data-table-cell",
                                      {
                                        staticClass:
                                          "text-left bg-gray-300 italic text-gray-700",
                                        attrs: { colspan: _vm.headers.length },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "ml-16 text-left" },
                                          [
                                            _vm._v(
                                              "No data found for this option"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", {
                              staticClass: "bg-white text-center",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
            _vm.totalItems
              ? _c(
                  "box",
                  { staticClass: "mb-8" },
                  [
                    _c("box-column", {
                      attrs: {
                        title: "Impressions",
                        value: _vm.totals.impressions,
                      },
                    }),
                    _c("box-column", {
                      attrs: { title: "Clicks", value: _vm.totals.clicks },
                    }),
                    _c("box-column", {
                      attrs: { title: "Cost" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "text-3xl text-orange-500" },
                                  [_vm._v(_vm._s(_vm.costTotal))]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2908764896
                      ),
                    }),
                    _c("box-column", {
                      attrs: { title: "Total items", value: _vm.totalItems },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("sun-pagination-page", {
              staticClass: "mb-8",
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
              },
              on: {
                changePage: _vm.changePage,
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }