import { api } from '.';
import { ANALYTICS_RESOURCE, BREAKDOWN_RESOURCE, CAMPAIGNS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import SocialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = (clientId, campaignId, breakdown) => ({
  [ANALYTICS_RESOURCE]: undefined,
  [CLIENT_RESOURCE]: clientId,
  [CAMPAIGNS_RESOURCE]: campaignId,
  [BREAKDOWN_RESOURCE]: breakdown,
});

/**
 * @param {QueryParamsBuilder} params
 * @param {string} campaignId
 * @param {string} breakdown
 * @returns {Promise.<{{data:Array.<CampaignAnalytics>,meta:object}}>}
 */
export async function getAnalyticsByCampaign(params = new QueryParamsBuilder(), clientId, campaignId, breakdown) {
  const partialUrl = api.createUrl(getResources(clientId, campaignId, breakdown));

  const { data } = await api.get(partialUrl, params.build(), { preventCancel: true });
  return new ResponseBuilder(SocialAudienceEntityParser.parse(data), data.meta).build();
}
