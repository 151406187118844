import { api } from '.';
import { createAnalyticsReport } from '@/services/shared/analytics/createAnalyticsReport';

/**
 * @param {string} clientId
 * @param {object} config
 * @returns {Promise.<{id:string}>}
 */
export default function (clientId, config) {
  const { format, type, ...params } = config;

  const payload = {
    format,
    payload: { ...params },
    type,
  };
  return createAnalyticsReport(api, clientId, payload);
}
