var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "totals flex flex-row items-center w-full bg-gray-100 rounded border border-gray-300 shadow text-xs font-bold text-gray-500 overflow-auto",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }