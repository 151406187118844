var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("store-columns-selector", {
    attrs: { "default-columns": _vm.defaultColumns },
    on: {
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }