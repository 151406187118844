import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, CAMPAIGNS_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import SocialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = clientId => ({
  [ANALYTICS_RESOURCE]: null,
  [CLIENT_RESOURCE]: clientId,
  [CAMPAIGNS_RESOURCE]: undefined, // TODO: en un futuro esto se borrará
});

/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{{data:Array.<CampaignAnalytics>,meta:object}}>}
 */
export async function getAnalytics(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(clientId));
  params.addInclude('platform', 'client', 'advertiser');
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(SocialAudienceEntityParser.parse(data), data.meta).build();
}
