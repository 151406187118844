<template>
  <store-columns-selector :default-columns="defaultColumns" @change="$emit('change', $event)" />
</template>
<script>
import StoreColumnsSelector from '@/components/molecules/modules/Analytics/ColumnsSelector/StoreColumnsSelector';
import { getSettings, updateSettings } from '@/services/modules/socialAudience/user';

export default {
  name: 'ColumnSelectorSocialAudience',
  components: {
    StoreColumnsSelector,
  },
  provide: () => ({
    getUserSettings: getSettings,
    updateUserSettings: updateSettings,
  }),
  props: {
    defaultColumns: {
      type: Array,
      required: true,
    },
  },
};
</script>
