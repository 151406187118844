/// <reference path="../../../..//model/shared/analytics/ReportStatus.js" />
import { api } from '.';
import getAnalyticsReportStatus from '@/services/shared/analytics/getAnalyticsReportStatus';

/**
 * @param {string} clientId
 * @param {string} reportId
 * @returns {Promise<ReportStatus>}
 */
export default function (clientId, reportId) {
  return getAnalyticsReportStatus(api, clientId, reportId);
}
