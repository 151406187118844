<template>
  <asterix-section :status="sectionStatus">
    <template #header-right>
      <div>
        <sun-date-picker
          id="filter-date"
          :key="datepickerKey"
          class="w-auto text-sm font-light text-gray-800"
          name="filterDates"
          :value="quickFiltersDate"
          separator="to"
          cancel-error
          show-date-list
          :date-list="dateList"
          required
          :single-date-picker="false"
          @change="onQuickFiltersDate($event.value)"
        />
      </div>
    </template>
    <template #content>
      <sun-filter-layout
        id="filter-box"
        class="mb-5"
        :filters-available="availableFilters"
        :filters-added="filterFind"
        :has-error="anyError"
        show-reset
        @change="changeFilters"
        @close="filtersResetErrors"
        @remove="filtersResetErrors"
        @reset="resetFilters"
      >
        <template #quickFilters>
          <div class="flex flex-wrap lg:flex-no-wrap min-w-max">
            <div class="m-1">
              <sun-search-input
                class-input="text-sm"
                :value="filterQuick['campaign.name']"
                @search="onQuickFiltersSearch($event, 'campaign.name')"
              />
            </div>
          </div>
        </template>
        <!--Filters Slot :Start -->
        <template #[`filter.campaign.name`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
        <template #[`filter.campaign.status`]="{ filter, onSelect, value, isDuplicate }">
          <sun-select
            add-hex-color="orange"
            class-input="pt-0 shadow-none rounded-none multiselect"
            class="w-24"
            close-on-select
            disable-selected-options
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            :label="filter.textBy"
            :options="filter.options"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :track-by="filter.trackBy"
            :value="filtersMakeFiltersForSelect(value)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
        <template #[`filter.advertiser.id`]="{ filter, onSelect, value, isDuplicate }">
          <advertiser-filter
            :api="currentApi"
            :client-id="activeClient.id"
            disable-selected-options
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            :service="getAdvertisers"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :value="filtersMakeFiltersForSelect(value)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
          />
        </template>
        <template #[`filter.campaign.type`]="{ filter, onSelect, value, isDuplicate }">
          <sun-select
            add-hex-color="orange"
            class-input="pt-0 shadow-none rounded-none multiselect"
            class="w-24"
            close-on-select
            disable-selected-options
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            :label="filter.textBy"
            :options="filter.options"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :track-by="filter.trackBy"
            :value="filtersMakeFiltersForSelect(value)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
        <!--Filters Slot :End -->
      </sun-filter-layout>

      <div class="flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center">
        <div class="flex flex-row items-center mb-2 lg:pr-1 lg:mb-0">
          <columns-selector-social-audience :default-columns="availableColumns" @change="headers = $event" />
        </div>
        <div class="h-10 mb-2 breakdown lg:mb-0 flex items-center">
          <breakdown-wrapper
            v-model="breakdown"
            label="Select Breakdowns"
            :items="breakdownOptions"
            hide-secondary
            :disabled="breakdownDisabled"
            @reset="resetBreakdown"
          />
        </div>
        <div class="flex flex-row items-center mb-2 ml-1 lg:mb-0">
          <asterix-dropdown
            button-classes="flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center"
            :disabled="isLoading || reportToastLoading"
            :options="reportOptions"
            @change="handlerReport"
          >
            <span class="block w-4 mr-2">
              <download-svg />
            </span>
            <template #dropdown-header>
              <p class="p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap">Download as</p>
            </template>
            <template #icon="state">
              <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
                <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
              </span>
            </template>
          </asterix-dropdown>
        </div>
      </div>

      <div class="flex flex-col items-center justify-center w-full pb-8">
        <sun-data-table
          class="w-full"
          :headers="availableHeaders"
          :content="items"
          :detailed="!!breakdown.primary"
          hoverable
          :loading="isLoading"
          @expandDetail="handlerDetail"
          @sort="onSortTable"
        >
          <template #[`col.campaign.name`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass" sticky :detail-cell="!breakdown.primary">
              {{ item.name }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.start`]="{ item }">
            <sun-data-table-cell>
              <span class="inline-flex">
                <date-tooltip v-if="item.startDate" :date="item.startDate" class="date" />
                <span v-else>{{ notAvailable }}</span>
              </span>
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.end`]="{ item }">
            <sun-data-table-cell>
              <span class="inline-flex">
                <date-tooltip v-if="item.endDate" :date="item.endDate" class="date" />
                <span v-else>{{ notAvailable }}</span>
              </span>
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill :status="item.status.value" :color="item.status.color" />
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.type`]="{ item }">
            <sun-data-table-cell>
              {{ item.type.name }}
            </sun-data-table-cell>
          </template>
          <template #[`col.advertiser.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.advertiser.name }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.impressions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.impressions | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.clicks`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.clicks | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.cost`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.cost.value | currency(item.analytic.cost.currency) }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.netCost`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.netCost.value | currency(item.analytic.netCost.currency) }}
            </sun-data-table-cell>
          </template>
          <template #[`col.client.finance.fee`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.client.finance.fee | percentage }}
            </sun-data-table-cell>
          </template>
          <template #[`col.client.finance.techCost`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.client.finance.techCost | percentage }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.viewRate`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.viewRate | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.adStarted`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.adStarted | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.ad25PercentCompleted`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.ad25PercentCompleted | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.ad50PercentCompleted`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.ad50PercentCompleted | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.ad75PercentCompleted`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.ad75PercentCompleted | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.adCompleted`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.adCompleted | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #[`col.campaign.clickThroughRate`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.analytic.metrics.global.clickThroughRate | numFormat }}
            </sun-data-table-cell>
          </template>
          <template #detailed="{ item }">
            <sun-data-table-row v-for="(detail, $iRow) in item.children" :key="$iRow + '_' + item.id">
              <sun-data-table-cell detail-cell sticky />
              <template v-for="(column, $index) in availableHeaders">
                <template v-if="column && column.visible">
                  <sun-data-table-cell :key="`${$index}_${column}`" :class="[`text-${column.align}`]">
                    <template v-if="column.value === BREAKDOWN_COLUMNS.NAME.value">
                      <div class="inline-flex w-full">
                        <date-tooltip
                          v-if="breakdown.primary === 'Date'"
                          :date="detail.analytic.breakdown"
                          class="date"
                        />
                        <template v-else>
                          {{ detail.analytic.breakdown }}
                        </template>
                      </div>
                    </template>
                    <template v-else-if="column.value === COLUMNS.DATE_START.value">
                      <span class="inline-flex">
                        <date-tooltip v-if="detail.startDate" class="date" :date="detail.startDate" />
                        <span v-else>{{ notAvailable }}</span>
                      </span>
                    </template>
                    <template v-else-if="column.value === COLUMNS.DATE_END.value">
                      <span class="inline-flex">
                        <date-tooltip v-if="detail.endDate && column.value" class="date" :date="detail.endDate" />
                        <span v-else>{{ notAvailable }}</span>
                      </span>
                    </template>
                    <template v-else-if="column.value === COLUMNS.IMPRESSIONS.value">
                      {{ detail.analytic.metrics.global.impressions | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.CLICKS.value">
                      {{ detail.analytic.metrics.global.clicks | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.COST.value">
                      {{ detail.analytic.cost.value | currency(item.analytic.cost.currency) }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.NET_COST.value">
                      {{ detail.analytic.netCost.value | currency(item.analytic.netCost.currency) }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.VTR.value">
                      {{ detail.analytic.metrics.global.viewRate | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.AD_STARTED.value">
                      {{ detail.analytic.metrics.global.adStarted | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.AD_25_PERCENT_COMPLETED.value">
                      {{ detail.analytic.metrics.global.ad25PercentCompleted | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.AD_50_PERCENT_COMPLETED.value">
                      {{ detail.analytic.metrics.global.ad50PercentCompleted | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.AD_75_PERCENT_COMPLETED.value">
                      {{ detail.analytic.metrics.global.ad75PercentCompleted | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.AD_COMPLETED.value">
                      {{ detail.analytic.metrics.global.adCompleted | numFormat }}
                    </template>
                    <template v-else-if="column.value === COLUMNS.CTR.value">
                      {{ detail.analytic.metrics.global.clickThroughRate | numFormat }}
                    </template>
                    <template v-else> - </template>
                  </sun-data-table-cell>
                </template>
              </template>
            </sun-data-table-row>

            <asterix-table-row-load-more
              v-if="canGetMoreItems(item)"
              :loading="detailPagination.isLoading(item.id)"
              :cols="availableHeaders.length"
              @click="handleNextDetail(item)"
            />

            <sun-data-table-row v-if="!item.children.length">
              <sun-data-table-cell detail-cell class="bg-gray-300" />
              <sun-data-table-cell :colspan="headers.length" class="text-left bg-gray-300 italic text-gray-700">
                <p class="ml-16 text-left">No data found for this option</p>
              </sun-data-table-cell>
            </sun-data-table-row>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white text-center" />
          </template>
        </sun-data-table>
      </div>

      <box v-if="totalItems" class="mb-8">
        <box-column title="Impressions" :value="totals.impressions" />
        <box-column title="Clicks" :value="totals.clicks" />
        <box-column title="Cost">
          <template #value>
            <span class="text-3xl text-orange-500">{{ costTotal }}</span>
          </template>
        </box-column>
        <box-column title="Total items" :value="totalItems" />
      </box>

      <sun-pagination-page
        class="mb-8"
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        @changePage="changePage"
        @changePerPage="changeItemsPerPage"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { USER } from '@/store/modules/auth/keys';
import { SAVE_BREAKDOWN } from '@/store/modules/filters/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { COLUMNS, CONFIG, BREAKDOWN_COLUMNS } from './config';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import metaInfo from '@/mixins/common/metaInfo';
import filtersMixin from '@/mixins/filters/filtersMixin';
import Box from '@/components/atoms/Box/Box';
import BoxColumn from '@/components/atoms/Box/BoxColumn';
import ColumnsSelectorSocialAudience from '@/components/molecules/shared/columnsSelector/ColumnsSelectorSocialAudience.vue';
import BreakdownWrapper from '@/components/molecules/modules/Analytics/Breakdown/BreakdownWrapper.vue';
import AdvertiserFilter from '@/components/molecules/shared/filters/AdvertiserFilter';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import DownloadSvg from '@/components/icons/DownloadSvg';
import AsterixSection from '@/components/templates/AsterixSection';
import StatusPill from '@/components/atoms/StatusPill';
import { SocialAudienceApi } from '@/services/api/SocialAudienceApi';
import { getAdvertisers } from '@/services/modules/socialAudience/advertiser';
import {
  getAnalytics,
  getAnalyticsByCampaign,
  getAnalyticsTotals,
  createAnalyticsReport,
  getAnalyticsReport,
  getAnalyticsReportStatus,
} from '@/services/modules/socialAudience/analytics';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { ANALYTICS_REPORT_TOAST, SET_ANALYTICS_REPORT_TOAST } from '@/store/modules/analyticsReportToast/keys';
import { stringToDate } from '@/filters/dateFilters';
import ApiRequest from '@/utils/apiRequest';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import currency from '@/filters/currency';
import DetailPagination from '@/model/shared/analytics/DetailPagination';

export default {
  name: 'Analytics',
  components: {
    AdvertiserFilter,
    AsterixDropdown,
    AsterixSection,
    Box,
    BoxColumn,
    BreakdownWrapper,
    ColumnsSelectorSocialAudience,
    DownloadSvg,
    StatusPill,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    AsterixTableRowLoadMore: () => import('@/components/molecules/shared/AsterixTableRowLoadMore'),
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        'campaign.name': undefined,
        range_start: undefined,
        range_end: undefined,
      },
    }),
    indexMixin,
    metaInfo,
  ],
  data: () => ({
    COLUMNS,
    BREAKDOWN_COLUMNS,
    datepickerKey: 0,
    dateList: CONFIG.dateList,
    defaultDate: CONFIG.defaultDate,
    currentApi: new SocialAudienceApi(),
    isLoading: false,
    notAvailable: 'N/A',
    reportOptions: CONFIG.reportOptions,
    breakdownOptions: CONFIG.breakdown,
    breakdown: { primary: null, secondary: null },
    /** @type {Array.<{name:string,value:string,visible:boolean,order:number}>} */
    headers: CONFIG.defaultColumns,
    items: [],
    totals: {
      impressions: 0,
      clicks: 0,
    },
    costTotal: '',
    cacheDetails: new Map(),
    detailPagination: new DetailPagination(),
  }),
  computed: {
    ...mapGetters({
      user: USER,
      analyticsReportToast: ANALYTICS_REPORT_TOAST,
    }),
    defaultSort() {
      return { field: COLUMNS.DATE_START.value, order: 'DESC' };
    },
    reportToastLoading() {
      return !!this[ANALYTICS_REPORT_TOAST]?.id;
    },
    contextUserRole() {
      return this.user?.contextRoles.find(c => c.context === CONTEXTS.SOCIAL_AUDIENCE.id).role;
    },
    availableColumns() {
      return this.headers.filter(c => !c.rolesAllowed.length || c.rolesAllowed.includes(this.contextUserRole));
    },
    availableHeaders() {
      const columnsByRole = this.headers.filter(
        c => !c.rolesAllowed.length || c.rolesAllowed.includes(this.contextUserRole)
      );
      if (this.sort?.field === BREAKDOWN_COLUMNS.NAME.value) BREAKDOWN_COLUMNS.NAME.sort = this.sort.order;
      else BREAKDOWN_COLUMNS.NAME.sort = true;
      return [BREAKDOWN_COLUMNS.NAME].concat(columnsByRole);
    },
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : this.defaultDate.endDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
    breakdownDisabled() {
      return this.isLoading || this.detailPagination.loadingItems.length > 0;
    },
  },
  watch: {
    breakdown: 'handlerBreakdown',
  },
  created() {
    this.setBreakdown();
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();
    this.setDefaultDateFilter();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({
      saveBreakdown: SAVE_BREAKDOWN,
      createToast: CREATE_TOAST,
      showReportToast: SET_ANALYTICS_REPORT_TOAST,
    }),

    async changeFilters(filters) {
      await this.updateFilters(filters);
      await this.getTableItems();
    },

    getAdvertisers(params) {
      params.addInclude('platform', 'category', 'subCategory');
      return getAdvertisers(params, this.activeClient.id);
    },

    setFilterQuick({ startDate, endDate }) {
      endDate.setHours(23, 59, 59, 0);
      startDate.setHours(0, 0, 0, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      // TODO: Is this necessary?
      this.datepickerKey++;
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },

    changePage(page) {
      this.goToPage({ page });
    },
    async getTotals() {
      const auxParams = this.setParamsToTable();
      const { global, cost } = await getAnalyticsTotals(this.activeClient.id, auxParams);
      this.totals = global;
      this.costTotal = currency(cost.amount, cost.currency);
    },

    // Back dont like "campaign.name" or "client.finance.fee"
    decodeSortFieldName(field) {
      switch (field) {
        case COLUMNS.ADVERTISER.value:
          return 'advertiser';
        case COLUMNS.FEE.value:
        case COLUMNS.TECH_COST.value:
          return field.replace('client.', '');
        default:
          return field.replace('campaign.', '');
      }
    },
    async handlerReport({ value: format }) {
      const auxParams = this.setParamsToTable();

      try {
        const config = {
          sort: auxParams.sort,
          filter: auxParams.filters,
          format,
          type: 'CAMPAIGN_LIST',
          columns: this.availableHeaders.filter(c => c.visible).map(c => this.decodeSortFieldName(c.value)),
          breakdown: this.breakdown.primary,
          subBreakdown: this.breakdown.secondary,
        };

        const {
          data: { id: reportId },
        } = await createAnalyticsReport(this.activeClient.id, config);

        if (reportId) {
          this.showReportToast({
            id: reportId,
            format: format.toLowerCase(),
            client: this.activeClient,
            reportEndpoints: { getReport: getAnalyticsReport, getReportStatus: getAnalyticsReportStatus },
          });
        }
      } catch (error) {
        this.createToast(Toast.error('Analytics', 'An error occurred while generating the report'));
      }
    },

    async handlerBreakdown({ primary, secondary }, previousBreakdown) {
      this.addQueryParams({ breakdown: primary });
      if (secondary) {
        this.addQueryParams({ subBreakdown: secondary });
      } else {
        this.removeQueryParam('subBreakdown');
      }

      this.saveBreakdown({ idView: this.$route.name, breakdown: { primary, secondary } });

      const isFirstTime = !previousBreakdown;
      const hasBreakdownPrimaryChanged = previousBreakdown?.primary !== primary;
      const hasBreakdownSecondaryChanged = previousBreakdown?.secondary !== secondary;
      const hasBreakdownChanged = hasBreakdownPrimaryChanged || hasBreakdownSecondaryChanged;

      if (hasBreakdownChanged && !isFirstTime) {
        await this.queryParamsRouterReplace();
      }

      if (hasBreakdownPrimaryChanged && !isFirstTime) {
        this.eventOrder(this.defaultSort);
        this.detailPagination.reset();
      }

      if (!isFirstTime) {
        await this.getTableItems();
      }
    },
    resetBreakdown() {
      this.breakdown = { primary: undefined, secondary: undefined };
    },
    setBreakdown() {
      const retrievedPrimary = this.queryParams.breakdown || this.storedBreakdown?.primary;
      const retrievedSecondary = this.queryParams.subBreakdown || this.storedBreakdown?.secondary;

      this.breakdown.primary = retrievedPrimary;
      this.breakdown.secondary = retrievedSecondary;
    },

    async updateFilters(filters = []) {
      this.replaceQueryParams({ page: 1, breakdown: this.queryParams.breakdown || null });

      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL();
    },

    async getTableItems() {
      this.sectionStatus = SECTION_STATUS.OK;
      this.isLoading = true;
      const { isCancel } = await ApiRequest(async () => {
        this.items = [];
        this.cacheDetails.clear();

        const auxParams = this.setParamsToTable();

        auxParams.page = this.currentPage;
        auxParams.itemsPerPage = this.itemsPerPage;

        const { data, meta } = await getAnalytics(this.activeClient.id, auxParams);
        this.items = data;
        this.totalPages = meta.totalPages || 1;
        this.totalItems = meta.totalResults || this.items.length;

        await this.getTotals();
      }).catch(() => {
        this.sectionStatus = SECTION_STATUS.ERROR;
      });
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    canGetMoreItems(item) {
      return this.detailPagination.isLoading(item.id) || !this.detailPagination.isLastPage(item.id);
    },
    handleNextDetail(item) {
      const nextPage = this.detailPagination.nextPage(item.id);
      if (!nextPage) return;

      const index = this.items.findIndex(row => row.id === item.id);
      if (index >= 0) {
        this.handlerDetail({ index, item, expand: true }, nextPage);
      }
    },
    async handlerDetail({ index, item, expand }, currentItemPage = 1) {
      this.detailPagination.addItem(item.id);

      if (expand && (!this.cacheDetails.has(item.id) || currentItemPage > 1)) {
        this.detailPagination.setLoading(item.id, true);
        const pageParams = new QueryParamsBuilder(currentItemPage, this.detailPagination.getItemsPerPage());
        const auxParams = this.setParamsToTable(pageParams);

        let children;
        try {
          const { data, meta } = await getAnalyticsByCampaign(
            auxParams,
            this.activeClient.id,
            item.id,
            this.breakdown.primary
          );
          children = data;
          this.detailPagination.setMaxPage(item.id, meta.totalPages);
          this.cacheDetails.set(item.id, index);
        } catch (error) {
          children = [];
          this.createToast(
            Toast.error(`Oops! We couldn't get the data`, 'Please try again later or review the filters.')
          );
        } finally {
          this.detailPagination.setLoading(item.id, false);
        }

        if (currentItemPage > 1) {
          children = this.items[index].children.concat(children);
        }
        this.$set(this.items[index], 'children', children);
      }
    },
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      await this.changeFilters(this.filterFind);
    },
  },
};
</script>

<style scoped>
.max-w-2\/3 {
  max-width: 66%;
}

.breakdown {
  min-width: 280px;
}

.download-report {
  min-width: 100px;
}

::v-deep .border-r-transparent {
  border-right-color: transparent;
}

.date.z-10 {
  @apply z-0;
}

::v-deep table.sun-table tbody tr td {
  @apply text-xs;
}
</style>
