import { ROLES } from '@/model/shared/roles';
import { addDays } from '@/utils/dateTime/addDays';
import { TODAY } from '@/utils/dateTime/today';
import { CURRENT_WEEK, LAST_WEEK } from '@/utils/dateTime/week';
import { CURRENT_MONTH, LAST_MONTH } from '@/utils/dateTime/month';
import Column from '@/model/shared/Column';
import FILTERS_CONFIG from '@/model/modules/socialAudience/filtersConfig.js';

export const BREAKDOWN_COLUMNS = Object.freeze({
  NAME: new Column('NAME', 'campaign.name').asSort().setClass('font-bold').asStickyColumn(),
});

export const COLUMNS = {
  DATE_START: new Column('DATE START', 'campaign.start').asSort(),
  DATE_END: new Column('DATE END', 'campaign.end').asSort(),
  STATUS: new Column('STATUS', 'campaign.status').asSort(),
  TYPE: new Column('TYPE', 'campaign.type').asSort(),
  ADVERTISER: new Column('ADVERTISER', 'advertiser.name').asSort(),
  IMPRESSIONS: new Column('IMPRESSIONS', 'campaign.impressions').asSort().toRight(),
  CLICKS: new Column('CLICKS', 'campaign.clicks').asSort().toRight(),
  NET_COST: new Column('NET COST', 'campaign.netCost')
    .asSort()
    .toRight()
    .setAllowedRoles([ROLES.ADMIN.id, ROLES.AD_OPS.id]),
  FEE: new Column('FEE', 'client.finance.fee').toRight().setAllowedRoles([ROLES.ADMIN.id, ROLES.AD_OPS.id]),
  TECH_COST: new Column('TECH COST', 'client.finance.techCost')
    .toRight()
    .setAllowedRoles([ROLES.ADMIN.id, ROLES.AD_OPS.id]),
  COST: new Column('COST', 'campaign.cost').asSort().toRight(),
  VTR: new Column('VTR', 'campaign.viewRate').asSort().toRight(),
  AD_STARTED: new Column('AD STARTED', 'campaign.adStarted').asSort().toRight(),
  AD_25_PERCENT_COMPLETED: new Column('AD 25 PERCENT COMPLETED', 'campaign.ad25PercentCompleted').asSort().toRight(),
  AD_50_PERCENT_COMPLETED: new Column('AD 50 PERCENT COMPLETED', 'campaign.ad50PercentCompleted').asSort().toRight(),
  AD_75_PERCENT_COMPLETED: new Column('AD 75 PERCENT COMPLETED', 'campaign.ad75PercentCompleted').asSort().toRight(),
  AD_COMPLETED: new Column('AD COMPLETED', 'campaign.adCompleted').asSort().toRight(),
  CTR: new Column('CTR', 'campaign.clickThroughRate').asSort().toRight(),
};

const PERIODS = {
  TODAY: {
    title: 'Today',
    startDate: TODAY,
    endDate: TODAY,
  },
  YESTERDAY: {
    title: 'Yesterday',
    startDate: addDays(TODAY, -1),
    endDate: addDays(TODAY, -1),
  },
  LAST_7_DAYS: {
    title: 'Last 7 days',
    startDate: addDays(TODAY, -7),
    endDate: addDays(TODAY, -1),
  },
  LAST_14_DAYS: {
    title: 'Last 14 days',
    startDate: addDays(TODAY, -14),
    endDate: addDays(TODAY, -1),
  },
  LAST_30_DAYS: {
    title: 'Last 30 days',
    startDate: addDays(TODAY, -30),
    endDate: addDays(TODAY, -1),
  },
  THIS_WEEK: {
    title: 'This week',
    startDate: CURRENT_WEEK.startDate,
    endDate: TODAY,
  },
  THIS_MONTH: {
    title: 'This month',
    startDate: CURRENT_MONTH.startDate,
    endDate: TODAY,
  },
  LAST_WEEK: {
    title: 'Last week',
    startDate: LAST_WEEK.startDate,
    endDate: LAST_WEEK.endDate,
  },
  LAST_MONTH: {
    title: 'Last month',
    startDate: LAST_MONTH.startDate,
    endDate: LAST_MONTH.endDate,
  },
};

export const CONFIG = {
  reportOptions: [
    { id: 'CSV', name: 'CSV', value: 'CSV' },
    { id: 'XLS', name: 'XLS', value: 'XLS' },
  ],
  filters: [
    FILTERS_CONFIG.CAMPAIGN_NAME,
    FILTERS_CONFIG.ANALYTICS_STATUS,
    FILTERS_CONFIG.ADVERTISER_ID,
    FILTERS_CONFIG.ANALYTICS_TYPE,
  ],
  breakdown: {
    name: 'Group By',
    value: 'groupBy',
    items: [{ name: 'Default' }, { name: 'Date', value: 'Date' }],
  },
  defaultColumns: [
    COLUMNS.DATE_START,
    COLUMNS.DATE_END,
    COLUMNS.STATUS,
    COLUMNS.TYPE,
    COLUMNS.ADVERTISER,
    COLUMNS.IMPRESSIONS,
    COLUMNS.CLICKS,
    COLUMNS.COST,
    COLUMNS.NET_COST,
    COLUMNS.FEE,
    COLUMNS.TECH_COST,
    COLUMNS.VTR,
    COLUMNS.AD_STARTED,
    COLUMNS.AD_25_PERCENT_COMPLETED,
    COLUMNS.AD_50_PERCENT_COMPLETED,
    COLUMNS.AD_75_PERCENT_COMPLETED,
    COLUMNS.AD_COMPLETED,
    COLUMNS.CTR,
  ],
  defaultDate: PERIODS.THIS_MONTH,
  dateList: [
    PERIODS.TODAY,
    PERIODS.YESTERDAY,
    PERIODS.LAST_7_DAYS,
    PERIODS.LAST_14_DAYS,
    PERIODS.LAST_30_DAYS,
    PERIODS.THIS_WEEK,
    PERIODS.THIS_MONTH,
    PERIODS.LAST_WEEK,
    PERIODS.LAST_MONTH,
  ],
};
